<template>
  <div style="margin-top: 20px;">
    <el-button type="primary" style="margin-bottom: 20px;" size="small" @click="$router.back()"
        >返 回</el-button
      >
    <el-card>
      <div slot="header" class="clearfix" style="display: flex;">
        <el-image :src="require('@/assets/images/problemList/标签@2x.png')" style="width: 24px; height: 24px"></el-image>
        <span style="color: #00957e">{{ $route.params.list_id && $route.params.list_id !== '0' ? '编辑题单' : '添加题单' }}</span>
      </div>
      <form-create ref="form" v-model="formData" :inline="false" label-width="130px" :label-position="'right'" :components="components" @update="handleFormUpdate"></form-create>

      <el-button-group style="padding-top: 15px;display: flex;justify-content: flex-end;">
        <el-button type="primary" :disabled="loading"  @click="submitForm()">保存</el-button>
      </el-button-group>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "save",
  data(){
    return {
      formData:{},
      courseInfo:{},
      loading:false,
      components:[
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'user_id',required:true,disabled:true,label:'创建人', type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}},
              ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'is_open',required:true,label:'公开程度',type:'radio',data:[{label:'公开',value:1},{label:'私人',value:2},{label:'团队',value:0},]}},
              ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item', condition:'{{is_open}} == 0',props:{prop:'group_id',label:'选择团队',required:true, type:'select',multiple:true,data:{ url:'/xapi/user.group/list',params:{type:2}, labelOptions:{value:'id',label:'name'} }}},
              ]}
          ]
        },
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'title',label:'题单名称',required:true ,type:'input',editorConfig:'base' }},
              ]},
           
              {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'diff_id',required:true,label:'难度', type:'select',data: {url:'/xapi/question.question/getDiffList', labelOptions:{value:'id',label:'title'}}}},
              ]},
            {name:'el-col',props:{span:8},components:[
            {name:'form-item',props:{prop:'recommend',label:'是否推荐', type:'radio' ,data:[{label:'推荐',value:1},{label:'不推荐',value:0}]}},

              ]},
          ]
        },

        {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'duration',required:true,label:'时长'},
                  components:[
                    {name:'el-row' ,components:[
                        {name:'el-col',props:{span:12},components:[
                            {name:'form-item',props:{prop:'duration_hour',required:true , type:'number',options:{labelWidth:'0px'}},slots:{after:"小时"}},
                          ]},
                        {name:'el-col',props:{span:12},components:[
                            {name:'form-item',props:{prop:'duration_minute',required:true , type:'number',options:{labelWidth:'0px'}},slots:{after:"分钟"}},
                          ]},
                      ]
                    },
                  ]},
              ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'down_price',required:true,label:'下载价格', type:'number',step:0.01},slots:{after:"元"},
                // callback(c){
                //   c.props.defaultValue=_this.formData.down_price
                // }
              },
              ]},
            {name:'el-col',props:{span:8},components:[
            {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number',min:0}},

              ]},
          ]
        },
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'from_id',required:true,label:'来源', type:'select',data: {url:'/xapi/question.question/getFromList', labelOptions:{value:'id',label:'title'}}}},
              ]},
              {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'course_ids',label:'绑定课程', type:'select',multiple:true,data:{ url:'/xapi/course.course/getList', labelOptions:{value:'id',label:'course_name'} }}},
              ]},
            {name:'el-col',props:{span:8},components:[
            {name:'form-item',props:{prop:'province_id',label:'省份', type:'select' , data:{ name:'question_list', keys:'province_id' , isAll:false }}},

              ]},
            // {name:'el-col',props:{span:8},components:[
            //     {name:'form-item',props:{prop:'time_str',label:'时间', type:'year'},slots:{after:"年"}},
            //   ]},
          ]
        },
        // {name:'form-item',props:{prop:'know_ids',label:'知识点', type:'checkbox',data: {url:'/xapi/question.question/getKnowList', labelOptions:{value:'id',label:'title'}}}},
        {name:'form-item',props:{prop:'description',label:'题单介绍',required:true , type:'editor'},style:{position:'relative',zIndex:0}},
      ],
      isOnlyField:false,
    }
  },
  watch:{
    userInfo:{
      immediate:true,
      deep:true,
      handler(v,o){
        if(!this.$tool.obj_equal(v,o)){
          this.formData.user_id = (v || {}).id
        }
      },
    }
  },
  methods:{
    routeUpdate(){
      this.getDetail();
    },
    getDetail(){
      if(this.$route.params.list_id && this.$route.params.list_id !== '0'){
        this.loading = true;
        this.$http.post('/xapi/question.quest_list/detail' ,{id:this.$route.params.list_id}).then(({data , isOnlyField})=>{
          let {duration,duration_hour,duration_minute ,..._data} = data || {};
          if(duration && !duration_hour && !duration_minute){
            duration_hour = parseInt(duration/60/60)
            duration_minute = parseInt((duration - duration_hour*60*60)/60)
          }
          setTimeout(()=>{
            this.formData = {..._data,duration,duration_hour,duration_minute};
            console.log( this.formData," this.formData this.formData");
            this.isOnlyField = isOnlyField === true;
            this.$forceUpdate()
            },100)
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        this.formData = {user_id:this.userInfo.id || 0,...this.$route.query,...this.$route.params};
      }
    },
    handleFormUpdate({prop , value , vNode}){
      console.log(prop,'prop')
      console.log(value,'value')
      console.log(vNode,'vNode')
      if(this.formData.duration_hour || this.formData.duration_minute){
        this.formData.duration_hour = this.formData.duration_hour || 0;
        this.formData.duration_minute = this.formData.duration_minute || 0;
        this.formData.duration = parseInt(this.formData.duration_hour) * 60 * 60 + parseInt(this.formData.duration_minute) * 60;
      }else{
        this.formData.duration = null;
      }
      var selected = null;
      if(prop === 'province_id'){
        selected = value > 0 ? (vNode.parseData.filter(d=>d.value === value)[0] || null) : null;
        this.formData.province_name = selected ? selected.label : '';
      }
      // if(prop === 'from_id'){
      //   selected = value > 0 ? (vNode.parseData.filter(d=>d.value === value)[0] || null) : null;
      //   console.log(selected,"selected")
      //   if (selected) {
      //     this.formData.course_name =this.formData.course_name?this.formData.course_name: selected.course_name;
      //   }
      // }
    },
    submitForm(){
      this.$refs.form.validate((_form,valid , errors)=>{
        if(valid){
          this.loading = true;
          // this.$http.post('/xapi/question.quest_list/save' , {...this.$route.params ,..._form , province_name:this.formData.province_name}).then(({msg})=>{
          this.$http.post('/xapi/question.question_list/save' , {...this.$route.params ,..._form , province_name:this.formData.province_name}).then(({msg})=>{
            this.$handleMessage(msg , 'success',()=>{this.$handleRoute('/problemList/myProblemList')});
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.$handleMessage(errors[0].message , 'error');
        }
      } , this.isOnlyField)
    },
  }
}
</script>

<style scoped>

</style>